@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Roboto:wght@400&display=swap');

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  box-sizing: border-box;
}

.backButton {
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.backButton:hover {
  background-color: #0056b3;
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.title {
  font-family: 'Playfair Display', serif;
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
}

.content {
  max-width: 800px;
  width: 100%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.keyItem {
  text-align: left;
  font-size: 1.2em;
  color: #666;
  margin-bottom: 10px;
}

.keyTitle {
  font-weight: bold;
  color: #333;
}

.maleColor {
  color: #007bff;
}

.femaleColor {
  color: #ff69b4;
}

@media (max-width: 600px) {
  .title {
    font-size: 2em;
  }

  .keyItem {
    font-size: 1em;
  }
}
