@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Roboto:wght@400&display=swap');

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(to right, #f7f7f7, #e2e2e2);
  padding: 20px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.content, .facts, .history {
  max-width: 600px;
  width: 100%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.content:hover, .facts:hover, .history:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.title {
  font-family: 'Playfair Display', serif;
  font-size: 2.5em;
  color: #333;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 20px;
}

.exploreButton {
  padding: 12px 24px;
  font-size: 1.1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.exploreButton:hover {
  background-color: #0056b3;
}

.sectionTitle {
  font-family: 'Playfair Display', serif;
  font-size: 2em;
  color: #333;
  margin-bottom: 15px;
}

.factList {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
  color: #666;
  margin-bottom: 20px;
}

.factList li {
  margin-bottom: 10px;
}

.factList li::marker {
  color: #007bff; /* Match bullet color with button color */
}

.description {
  font-size: 1.1em;
  color: #666;
  text-align: left;
}

@media (max-width: 600px) {
  .title {
    font-size: 2em;
  }

  .subtitle {
    font-size: 1em;
  }

  .exploreButton {
    padding: 10px 20px;
    font-size: 1em;
  }

  .sectionTitle {
    font-size: 1.5em;
  }

  .description {
    font-size: 1em;
  }
}

.footer {
  margin-top: 40px;
  font-size: 0.9em;
  color: #999;
}

.footer a {
  color: #007bff;
  text-decoration: none;
  position: relative;
}

.footer a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 5px;
  right: 0;
  background: #007bff;
  transition: width 0.4s ease;
  -webkit-transition: width 0.4s ease;
}

.footer a:hover::after {
  width: 100%;
  left: 0;
  background: #007bff;
}

.madeWithLove {
  margin-top: 10px;
  color: #999;
  font-size: 0.9em;
  text-align: center;
}

.newsletter {
  margin: 40px 0;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.newsletterTitle {
  font-family: 'Playfair Display', serif;
  font-size: 1.5em;
  color: #333;
  margin-bottom: 15px;
}

.newsletterForm {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.newsletterInput {
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 25px;
  flex: 1;
}


.newsletterButton:hover {
  background-color: #0056b3;
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@keyframes slideDown {
  from {
    top: -50px;
    opacity: 0;
  }
  to {
    top: 20px;
    opacity: 1;
  }
}

.flashMessage {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  text-align: center;
  font-size: 1em;
  z-index: 1000;
  animation: slideDown 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}

.newsletterButton {
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #ccc; /* Grey color */
  border: none;
  border-radius: 25px;
  cursor: default; /* Default cursor */
  transition: none; /* Remove hover effects */
}



